import env from '@/extensions/utils/env'
import { ethers } from "iceconnect"
import ERC20Abi from '../abi/erc20-presale.json'
import NFTPresaleAbi from '../abi/nft-presale.json'
import NFTSaleAbi from '../abi/nft-sale.json'
import DividendsAbi from '../abi/dividends.json'

/**
 * default variables
 */
const RPC_URL = env('VUE_APP_RPC_URL')
const WALLET_OWNER = env('VUE_APP_OWNER_WALLET')
const NETWORK_ID = env('VUE_APP_NETWORK_ID')
const GAS_LIMIT = 150000
const GAS_LIMIT_NEW = 60000
const PROVIDER = new ethers.providers.JsonRpcProvider(RPC_URL)

/**
 * variables ContractERC20Presale
 */
const ADDRESS_CONTRACT_ERC20 = env('VUE_APP_ADDRESS_CONTRACT_ERC20')
const CONTRACT_ERC20 = new ethers.Contract(ADDRESS_CONTRACT_ERC20, ERC20Abi.abi, PROVIDER)

/**
 * variables ContractNFTPresale
 */
const ADDRESS_CONTRACT_NFTPresale = env('VUE_APP_ADDRESS_CONTRACT_NFTPresale')
const CONTRACT_NFTPresale = new ethers.Contract(ADDRESS_CONTRACT_NFTPresale, NFTPresaleAbi, PROVIDER)

/**
 * variables ContractNFTSale
 */
const ADDRESS_CONTRACT_NFTSale = env('VUE_APP_ADDRESS_CONTRACT_NFTSale')
const CONTRACT_NFTSale = new ethers.Contract(ADDRESS_CONTRACT_NFTSale, NFTSaleAbi, PROVIDER)

/**
 * variables Dividends
 */
const VUE_APP_ADDRESS_CONTRACT_Dividends = env('VUE_APP_ADDRESS_CONTRACT_Dividends')
const CONTRACT_Dividends = new ethers.Contract(VUE_APP_ADDRESS_CONTRACT_Dividends, DividendsAbi, PROVIDER)

/**
 * variables Nft-Investors
 */

const ADDRESS_CONTRACT_BalanceContract = env('VUE_APP_ADDRESS_CONTRACT_NFT_investors_balance')
const CONTRACT_BalanceContract = new ethers.Contract(ADDRESS_CONTRACT_BalanceContract, NFTSaleAbi, PROVIDER)

const NFT1 = env('VUE_APP_ADDRESS_CONTRACT_NFT_investors_1')
const CONTRACT_NFT1 = new ethers.Contract(NFT1, NFTPresaleAbi, PROVIDER)

const NFT3 = env('VUE_APP_ADDRESS_CONTRACT_NFT_investors_3')
const CONTRACT_NFT3 = new ethers.Contract(NFT3, NFTPresaleAbi, PROVIDER)

const NFT4 = env('VUE_APP_ADDRESS_CONTRACT_NFT_investors_4')
const CONTRACT_NFT4 = new ethers.Contract(NFT4, NFTPresaleAbi, PROVIDER)

const NFT5 = env('VUE_APP_ADDRESS_CONTRACT_NFT_investors_5')
const CONTRACT_NFT5 = new ethers.Contract(NFT5, NFTPresaleAbi, PROVIDER)

export {
  RPC_URL,
  NETWORK_ID,
  GAS_LIMIT,
  PROVIDER,
  WALLET_OWNER,
  CONTRACT_ERC20,
  ADDRESS_CONTRACT_NFTPresale,
  CONTRACT_NFTPresale,
  ADDRESS_CONTRACT_NFTSale,
  CONTRACT_NFTSale,
  CONTRACT_Dividends,
  GAS_LIMIT_NEW,

  CONTRACT_BalanceContract,
  NFT1,
  CONTRACT_NFT1,

  NFT3,
  CONTRACT_NFT3,

  NFT4,
  CONTRACT_NFT4,

  NFT5,
  CONTRACT_NFT5,
}