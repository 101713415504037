import { IDLE, LOADING, SUCCEEDED, FAILED } from '@/extensions/utils/statuses'
import { ethers } from 'iceconnect'
import { web3 } from '@/extensions/web3/wallet';

const getInitialState = () => {
  return {
    status: IDLE,
    error: null,
    address: null
  }
};

const state = getInitialState()

const getters = {
  getStatus (state) {
    return state.status
  },

  getAddressWallet (state) {
    return state.address
  }
}

const mutations = {
  fetchingSucceeded (state, { walletAddress }) {
    const adderss = ethers.utils.getAddress(walletAddress)

    state.status = SUCCEEDED
    state.error = null
    state.address = adderss
  },

  fetchingStarted (state) {
    state.status = LOADING
  },

  fetchingFailed (state, error) {
    state.status = FAILED
    state.error = error
  },

  clear (state) {
    Object.assign(state, getInitialState())
  }
}

const actions = {
  onWallet ({ commit }) {
    web3.wallet.connect({
      connecting: () => commit('fetchingStarted'),
      connectionSuccess: (response) => commit('fetchingSucceeded', response),
      connectionFailed: (error) => commit('fetchingFailed', error) 
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}