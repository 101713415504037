<template>
  <main class="main">
    <router-view/>
  </main>
</template>

<script setup>
import { defineComponent, provide, ref } from 'vue'

const typeRoadMap = ref('ru')

provide('typeRoadMap', typeRoadMap)

defineComponent({
  name: 'App'
})

</script>