import { IDLE, LOADING, SUCCEEDED, FAILED } from '@/extensions/utils/statuses'
import { web3 } from '@/extensions/web3/wallet'

const getInitialState = () => {
  return {
    data: {
      balance: null,
      price: null,
      status: IDLE,
      error: null,
    }
  }
};

const state = getInitialState()

const getters = {
  balance (state) {
    return String(40 - Number(state.data.balance))
  },

  price (state) {
    return state.data.price
  }
}

const mutations = {
  fetchingSucceeded (state, {balance, price}) {
    state.data.status = SUCCEEDED
    state.data.error = null
    state.data.balance = balance.toString()
    state.data.price = String(price)
  },

  fetchingStarted (state) {
    state.data.status = LOADING
  },

  fetchingFailed (state) {
    state.data.status = FAILED
  },

  clear (state) {
    Object.assign(state, getInitialState())
  }
}

const actions = {
  async balanceSale ({ commit }) {
    try {
      commit('fetchingStarted')
      const balance = await web3.contractNFTSale.balance()
      const price = await web3.contractNFTPresale.price()
      
      commit('fetchingSucceeded', {balance, price})
    } catch (e) {
      console.error(e)
      commit('fetchingFailed', e)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}