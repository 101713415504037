import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { createI18n, useI18n } from 'vue-i18n'
import { languages, defaultLocale } from './i18n'
import Countdown from 'vue3-flip-countdown'

import '@/assets/styles/index.scss'

const messages = Object.assign(languages)

const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages
})

createApp(App, {
    setup() {
      const {t} = useI18n()
      return t
    }
  })
  .use(Countdown)
  .use(i18n)
  .use(store)
  .use(router)
  .mount('#app')
