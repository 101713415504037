export const IDLE = 'idle';
export const PENDING = 'pending';
export const CHECKED = 'checked';
export const LOADING = 'loading';
export const LOADING_MORE = 'loadingMore';
export const FAILED = 'failed';
export const FAILED_MORE = 'failedMore';
export const DISABLED = 'disabled';
export const SUCCEEDED = 'succeeded';
export const FINAL = 'final';
export const CREATING = 'creating';
export const DELETING = 'deleting';
export const RESETTING = 'resetting';
export const SAVING = 'saving';
export const VIEWING = 'viewing';
export const LEARNED = 'learned';
export const UNLEARNED = 'unlearned';
export const SAVING_WITH_EXIT = 'savingWithExit';
export const COMPLETING = 'completing';
export const NOT_FOUND = 'not_found';
export const EMPTY = 'empty';
export const FOUND = 'found';
export const OK = 'ok';