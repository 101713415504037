import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/home')
  },
  // {
  //   path: '/presale',
  //   name: 'presale',
  //   component: () => import('@/pages/acquisition/presale')
  // },
  {
    path: '/nft-presale',
    name: 'nft-presale',
    component: () => import('@/pages/acquisition/nft-presale')
  },
  {
    path: '/dividends',
    name: 'dividends',
    component: () => import('@/pages/new-design/dividends')
  },
  {
    path: '/nft-investors',
    name: 'nft-investors',
    component: () => import('@/pages/new-design/nft-investors')
  },
  {
    path: '/personal-account',
    name: 'personal-account',
    component: () => import ('@/pages/personal-account'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/pages/personal-account/dashboard')
      },
      {
        path: '',
        name: 'staking-bdao',
        component: () => import('@/pages/personal-account/staking-bdao')
      },
      {
        path: '',
        name: 'staking-nft',
        component: () => import('@/pages/personal-account/staking-nft')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
