const runtimeConfig = {
  VUE_APP_OWNER_WALLET: process.env.VUE_APP_OWNER_WALLET,
  VUE_APP_RPC_URL: process.env.VUE_APP_RPC_URL,
  VUE_APP_NETWORK_ID: process.env.VUE_APP_NETWORK_ID,

  VUE_APP_ADDRESS_CONTRACT_ERC20: process.env.VUE_APP_ADDRESS_CONTRACT_ERC20,
  VUE_APP_ADDRESS_CONTRACT_NFTPresale: process.env.VUE_APP_ADDRESS_CONTRACT_NFTPresale,

  VUE_APP_TIME_NFT_PRESALE: process.env.VUE_APP_TIME_NFT_PRESALE,
  VUE_APP_TIME_ERC20_PRESALE: process.env.VUE_APP_TIME_ERC20_PRESALE,


  VUE_APP_ADDRESS_CONTRACT_NFTSale: process.env.VUE_APP_ADDRESS_CONTRACT_NFTSale,

  VUE_APP_ADDRESS_CONTRACT_Dividends: process.env.VUE_APP_ADDRESS_CONTRACT_Dividends,


  VUE_APP_ADDRESS_CONTRACT_NFT_investors_balance: process.env.VUE_APP_ADDRESS_CONTRACT_NFT_investors_balance,
  VUE_APP_ADDRESS_CONTRACT_NFT_investors_time: process.env.VUE_APP_ADDRESS_CONTRACT_NFT_investors_time,
  VUE_APP_ADDRESS_CONTRACT_NFT_investors_1: process.env.VUE_APP_ADDRESS_CONTRACT_NFT_investors_1,
  VUE_APP_ADDRESS_CONTRACT_NFT_investors_3: process.env.VUE_APP_ADDRESS_CONTRACT_NFT_investors_3,
  VUE_APP_ADDRESS_CONTRACT_NFT_investors_4: process.env.VUE_APP_ADDRESS_CONTRACT_NFT_investors_4,
  VUE_APP_ADDRESS_CONTRACT_NFT_investors_5: process.env.VUE_APP_ADDRESS_CONTRACT_NFT_investors_5
};

export default (name) => {
  return window?.configs?.[name] || runtimeConfig[name];
};