import {
  NFT1,
  NFT3,
  NFT4,
  NFT5,


  CONTRACT_NFT1,
  CONTRACT_NFT3,
  CONTRACT_NFT4,
  CONTRACT_NFT5
} from '@/extensions/web3/wallet/variables'

import { web3 } from '@/extensions/web3/wallet'

const getInitialState = () => {
  return {
    cards: [
      {
        id: 1,
        icon: require(`@/assets/img/new-desing/investors/icon-1.jpeg`),
        price: '0',
        sold: false,
        reserved: false,
        timer: true,
        connectWallet: false,
        addressContract: NFT1,
        contract: CONTRACT_NFT1,
      },
      {
        id: 2,
        icon: require(`@/assets/img/new-desing/investors/icon-2.jpeg`),
        price: 0,
        sold: true,
        reserved: false,
        timer: false,
        connectWallet: false,
        addressContract: null,
      },
      {
        id: 3,
        icon: require(`@/assets/img/new-desing/investors/icon-3.jpeg`),
        price: '0',
        sold: false,
        reserved: false,
        timer: true,
        connectWallet: false,
        addressContract: NFT3,
        contract: CONTRACT_NFT3,
      },
      {
        id: 4,
        icon: require(`@/assets/img/new-desing/investors/icon-4.jpeg`),
        price: '0',
        sold: false,
        reserved: false,
        timer: true,
        connectWallet: false,
        addressContract: NFT4,
        contract: CONTRACT_NFT4,
      },
      {
        id: 5,
        icon: require(`@/assets/img/new-desing/investors/icon-5.jpeg`),
        price: '0',
        sold: false,
        reserved: false,
        timer: true,
        connectWallet: false,
        addressContract: NFT5,
        contract: CONTRACT_NFT5,
      },
    ]
  }
};

const state = getInitialState()

const getters = {
  getCards (state) {
    state.cards.forEach((item) => {
      if (item.balance === '0') {
        item.sold = true
        item.timer = false
        item.reserved = false
        item.connectWallet = false
      }

      state.cards.forEach(item => {
        if (item.price) {
          item.price = Number(item.price).toFixed()
        }
      })

      if (!item.price) {
        item.price = 99
      }
    })


    return state.cards
  }
}

const mutations = {
  switchTimer (state, id) {
    const obj = state.cards.find(el => el.id === id)
    obj.connectWallet = true
    obj.timer = false
  },

  switchSold (state, id) {
    const obj = state.cards.find(el => el.id === id)
    obj.sold = true
    obj.timer = false
    obj.reserved = false
    obj.connectWallet = false
  },

  switchReserved (state, id) {
    const obj = state.cards.find(el => el.id === id)
    obj.sold = false
    obj.timer = false
    obj.reserved = true
    obj.connectWallet = false
  },

  setBalanceNFTs (state, {value}) {
    value.forEach((el, index) => {
      state.cards[index]['balance'] = el.value?.toString()
    })
  },
  
  setPrice (state, {priceCards}) {
    priceCards.forEach((el, index) => {
      state.cards[index].price = el.value?.toString()
    })
  }
}

const actions = {
  async balanceNFTs ({state, commit}) {
    try {
      const value = await Promise.allSettled(Array.from(state.cards)
        .map(card => {
          return new Promise((resolve, reject) => {
            web3.contractNFTInvestors.balance(card.addressContract)
            .then(b => resolve(b))
            .catch(err => reject(err))
          })
        }))

      commit('setBalanceNFTs', {value})
    } catch (e) {
      console.error(e)
    }
  },

  async cardsPrice ({state, commit}) {
    try {
      const priceCards = await Promise.allSettled(Array.from(state.cards)
        .map(card => {
          return new Promise((resolve, reject) => {
            web3.contractNFTInvestors.price(card.contract)
            .then(b => resolve(b))
            .catch(err => reject(err))
          })
        }))
        
      commit('setPrice', {priceCards})
    } catch (e) {
      console.error(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}