import { IDLE, LOADING, SUCCEEDED, FAILED } from '@/extensions/utils/statuses'
import { web3 } from '@/extensions/web3/wallet'

const getInitialState = () => {
  return {
    dataOwner: {
      balance: null,
      status: IDLE,
      error: null
    }
  }
};

const state = getInitialState()

const getters = {
  getOwnerBalance () {
    const maxSoldOut = 100
    /* Sold Out */
    // return Number(state.dataOwner.balance).toFixed(2)
    return maxSoldOut
  }
}

const mutations = {
  fetchingSucceeded (state, balance) {
    state.dataOwner.balance = balance
    state.dataOwner.status = SUCCEEDED
    state.dataOwner.error = null
  },

  fetchingStarted (state) {
    state.dataOwner.status = LOADING
  },

  fetchingFailed (state, error) {
    state.dataOwner.error = error
    state.dataOwner.status = FAILED
  },

  clear (state) {
    Object.assign(state, getInitialState())
  }
}

const actions = {
  async walletBalanceOwner ({ commit }) {
    try {
      commit('fetchingStarted')
      const balance = await web3.walletOwner.balance()

      commit('fetchingSucceeded', balance)
    } catch (e) {
      console.error(e)
      commit('fetchingFailed', e)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}