import { createStore } from 'vuex'

import wallet from './module/wallet'
import presale from './module/presale'
import NFTSale from './module/nft-sale'
import PersonalAccount from './module/personal-account'
import NewDesign from './module/new-design'

export default createStore({
  modules: {
    wallet,
    presale,
    NFTSale,
    PersonalAccount,
    NewDesign
  }
})