const getInitialState = () => {
  return {
  }
};

const state = getInitialState()

const getters = {

}

const mutations = {
  fetchingSucceeded () {
  },

  fetchingStarted () {
  },

  fetchingFailed () {
  },

  clear (state) {
    Object.assign(state, getInitialState())
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}