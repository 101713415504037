import { Wallet } from "iceconnect"
import { RPC_URL, NETWORK_ID } from './variables'
import { IDLE, LOADING, SUCCEEDED, FAILED } from '@/extensions/utils/statuses'

export class IceWallet {
  constructor () {

    this.data = {
      address: null,
      web3Provider: null,
      singer: null,
      status: IDLE,
      error: null
    }

    this.wallet = new Wallet({
      RPC_URL: RPC_URL,
      NETWORK_ID: NETWORK_ID,
      INFURA_ID: "",
      hooks: {
        connecting: () => this.fetchingStarted(),
        connectionSuccess: (response) =>  this.fetchingSucceeded(response),
        connectionFailed: (error) => this.fetchingFailed(error)
      }
    })

  }

  fetchingStarted () {
    this.data.status = LOADING
    this.data.error = null
  }

  fetchingSucceeded ({ web3Provider, walletAddress }) {
    this.data.web3Provider = web3Provider
    this.data.address = walletAddress
    this.data.singer = this.data.web3Provider.getSigner()
    this.data.status = SUCCEEDED
    this.data.error = null
  }

  fetchingFailed (error) {
    this.data.status = FAILED
    this.data.error = error
  }

  connect (callbacks) {
    this.wallet.ConnectWallet(callbacks)
  }
}